/**
 * 双字节字数统计
 *
 * @param {String} sContent
 * @param {Boolean|undefined} bIsTrim 是否要过滤前后空格，默认否。
 * @return {Number}
 */
export default function(sContent, bIsTrim){
    sContent = (sContent || "")
        .replace(/<br[^>]*?>/g, "**")   // 将换行符替换成一个双字节字符。
        .replace(/<[^>]*?>/g, "")   // 过滤 HTML标签。
        .replace(/[\r\n ]*/g, "")   // 过去换行和 tab 符。
        .replace(/\&nbsp;/g, " ")   // 空格转换。
        .replace(/[^\x00-\xff]/g, "**") // 转换成双字节统计。
        //.replace(/\s/g,"")          //过滤空格
        .replace(/[\r\n]/g, "")         //过滤回车
    ;

    if (bIsTrim) {
        sContent = $.trim(sContent);
    }

    return sContent.length / 2 + .5 >> 0    // 使用双字节统计，并且小数向上取整。
}
