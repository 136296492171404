import '../../css/common.scss';

import getUrlParam from  '../extends/components/getUrlParam.js';
import wordCount from '../extends/components/wordCount.js';
import template from '../extends/template.js';
import ajax from '../extends/components/ajax';
import Cookies from 'js-cookie';
import config from 'config';


/*
 * 获取头部昵称
 * 
 * @since 2018-5-21
 * @author xieyun
 * 
 */
if($("#header .top-nav").length == 1 || $("#mgt-header").length == 1){
    let res = {
        nickname: '',
        shopId: ''
    };

    if(config.getToken() != undefined){
        res.nickname = localStorage.getItem("nickname");
        res.shopId = localStorage.getItem("shopId");
    }

    if($("#header .top-nav").length == 1){
        $("#header .nav-right").prepend(template('nav-right-tmpl', res));
    }else{
        $("#mgt-header .header-user").prepend(template('user-info-tmpl', res));
    }
}


/*
 * 获取头部菜单数据+购物车数量
 * 
 * @since 2018-5-21
 * @author xieyun
 * 
 */
if($("#header .top-menu").length == 1){
    // 菜单
    ajax('/api-item/v1/top-category', {
        type: 'GET'
    }).then(function(res){
        const nCode = res.code;
        const sMsg = res.msg;

        if(nCode == 0){
            const oData = {
                nav: getUrlParam("nav"),
                result: res.result
            };
            const sHtml = template('menu-list-tmpl', oData);
            
            $("#header .menu-list").append(sHtml);
        }
    });  

    // 购物车
    if(config.getToken() != undefined){
        ajax('/api-member/v1/cart/count', {
            needLogin: true,
            type: 'GET'
        }).then(function(res){
            const nCode = res.code;
            const sMsg = res.msg;

            if(nCode == 0){
                if(res.result.num == 0){
                    $("#header .action-cart .num").hide();
                }else{
                    $("#header .action-cart .num").show();
                    $("#header .action-cart .num").html(res.result.num); 
                }
            }
        }); 
    }

    // 下拉菜单hover
    $(document)
        .on('mouseenter', '.menu-item', function() {
            $("#header .dropdown-menu").hide();
            $(this).find(".dropdown-menu").slideDown();
        })
        .on('mouseleave', '.menu-item', function() {
            $(this).find(".dropdown-menu").slideUp(0);
        })
    ;
}


/*
 * 退出登录
 * 
 * @since 2018-6-12
 * @author xieyun
 * 
 */
$(document).on('click', '[data-toggle="logout"]', function() {
    ajax('/api-member/v1/user/logout', {
        type: 'POST'
    }).then(function(res){
        const nCode = res.code;
        const sMsg = res.msg;

        if(nCode == 0 || nCode == 101){
            Cookies.remove('access_token');
            localStorage.removeItem("nickname"); 
            localStorage.removeItem("shopId"); 

            window.location.href = "/";
        }
    });  
});


/*
 * 刷新token
 * 
 * @since 2018-6-22
 * @author xieyun
 * 
 */
if(config.getToken() == 'undefined'){
    ajax('/api-member/v1/user-token/refresh', {
        type: 'POST'
    }).then(function(res){
        const nCode = res.code;
        const sMsg = res.msg;

        if(nCode == 0){
            Cookies.set('access_token', res.result["access-token"], {
                expires: 2 / 24
            });
        }
    });  
}


/*
 * 滚动监听
 * 
 * @since 2018-5-21
 * @author xieyun
 * 
 */
$(window).scroll(function(){ 
    // 是否开启置顶
    if(document.documentElement.scrollHeight <= document.documentElement.clientHeight + 70){
        return false;
    }

    //导航悬浮
    if($(window).scrollTop() > 34){
        $("#header .top-menu").addClass("navbar-fixed-top");
    }else{
        $("#header .top-menu").removeClass("navbar-fixed-top");
    }
    
    //左侧悬浮
    if($(window).scrollTop() >= 100){
        $("#suspension .suspension-top").fadeIn(300);
    }else{
        $("#suspension .suspension-top").fadeOut(300);
    }
});


/*
 * 置顶
 * 
 * @since 2018-5-21
 * @author xieyun
 * 
 */
$(document).on('click', '[data-toggle="top"]', function() {
    $("html,body").animate({
        scrollTop: 0
    }, 800);
});


/*
 * 搜索框
 * 
 * @since 2018-5-21
 * @author xieyun
 * 
 */
$(document)
    .on('click', '[data-toggle="searchShow"]', function(e) {
        $("#header .search-wrapper").removeClass("hide");
        e.stopPropagation();
    })
    .on('click', '[data-toggle="searchHide"]', function(e) {
        $("#header .search-wrapper").addClass("hide");
        e.stopPropagation();
    })
    .on('click', '[data-toggle="searcDelete"]', function(e) {
        e.stopPropagation();
    })
    .on('click', '[data-toggle="searchBtn"]', function(e) {
        if($("#header input[name=keyword]").val()){
            window.location.href = "/good/search?keyword=" + $("#header input[name=keyword]").val()
        }
        e.stopPropagation();
    })
;


/*
 * 购物车
 * 
 * @since 2018-6-28
 * @author xieyun
 * 
 */
$(document).on('click', '[data-toggle="cart"]', function() {
    if(config.getToken() != undefined){
        window.location.href = "/payment/cart";
    }else{
        window.location.href = "/account/login";
    }
});


/*
 * 官方Q群
 * 
 * @since 2018-8-7
 * @author xieyun
 * 
 */
$(document)
    .on('mouseover', '[data-toggle="qq"]', function() {
        $("#suspension .qq-overlay").removeClass("hide");
    })
    .on('mouseout', '[data-toggle="qq"]', function() {
        $("#suspension .qq-overlay").addClass("hide");
    })
;


/*
 * 客服是否展示
 * 
 * @since 2018-7-11
 * @author xieyun
 * 
 */
// if($("#suspension").length == 1){
//     if(config.getToken() != undefined){
//         $("#suspension .suspension-service").removeClass("hide");
//     }
// }

/*
 * 客服
 * 
 * @since 2018-7-9
 * @author xieyun
 * 
 */
$(document)
    .on('mouseover', '[data-toggle="service"]', function() {
        $("#suspension .service-overlay").removeClass("hide");
    })
    .on('mouseout', '[data-toggle="service"]', function() {
        $("#suspension .service-overlay").addClass("hide");
    })
;

$(document).on('click', '[data-toggle="service"]', function() {
    ajax('/api-member/im/user-info', {
        needLogin: true,
    }).then(function(res){
        const nCode = res.code;
        const sMsg = res.msg;

        if(nCode == 0){
            const oData = res.result;

            // 客服
            $("#J_Service").removeClass("hide");
            if($("#J_Service").find("#wkit-content").length == 0){
                WKIT.init({
                    container: document.getElementById('J_Service'),
                    width: 400,
                    height: 500,
                    uid: oData.userid,
                    appkey: 24943917,
                    credential: oData.password,
                    touid: '狐狸购官服',
                    sendBtn: true,
                    sendMsgToCustomService: true
                });
            }
           
            $(".suspension-wrapper").hide();  
        }
    }); 

    // 关闭
    $("#J_Service").find(".icon").on("click", function(){
        $("#J_Service").addClass("hide");
        $(".suspension-wrapper").show();  
    });
});


/*
 * 拓展验证规则
 * 
 * @since 2018-5-24
 * @author xieyun
 * 
 */

$.validator.setDefaults({
    debug: true,
    onkeyup: false
});

/*手机号码*/
$.validator.addMethod("formatPhone", function(value, element){  
    let length = value.length,
        sPhoneReg = /^(((13[0-9]{1})|(15[0-9]{1})|(16[0-9]{1})|(18[0-9]{1})|(17[0-9]{1})|(19[0-9]{1}))+\d{8})$/
    ;

    return this.optional(element) || (length == 11 && sPhoneReg.test(value));
}, "手机号格式不正确，请重新输入");

/*密码*/
$.validator.addMethod("formatPwd", function(value, element){  
    let length = value.length,
        sPwdReg = /^(?![\d]+$)(?![a-zA-Z]+$)(?![^\da-zA-Z]+$).{6,20}$/ 
    ;

    return this.optional(element) || (sPwdReg.test(value));
}, "密码过于简单，请更改复杂密码");

/*收获人*/
$.validator.addMethod("receiverName", function(value, element){
    return this.optional(element) || wordCount(value) < 8;
}, "输入的字数超限");

/*收获地址*/
$.validator.addMethod("receiverAddress", function(value, element){
    return this.optional(element) || wordCount(value) < 50;
}, "输入的字数超限");

/*用户昵称*/
$.validator.addMethod("formatNickname", function(value, element){  
    let length = value.length,
        sNickName = /^([\u4E00-\u9FA5A-Za-z0-9]{0,15})$/
    ;

    return this.optional(element) || (length <= 15 && sNickName.test(value));
}, "昵称不符合要求");

/*价格*/
$.validator.addMethod("formatPrice", function(value, element){  
    let sPriceReg = /^\+?(?!0+(\.00?)?$)\d+(\.\d\d?)?$/;

    return this.optional(element) || sPriceReg.test(value);
}, "价格不符合要求");

/*数量*/
$.validator.addMethod("formatNumber", function(value, element){  
    let sNumberReg = /^[1-9]\d*$/;

    return this.optional(element) || sNumberReg.test(value);
}, "数量必须为正整数");



